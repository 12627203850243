.spinner {
  --spinner-rect-width: 3px;
  --spinner-rect-spacing: 2px;
  --spinner-height: 24px; /* 24px is just enough to fit inside buttons */

  display: flex;
  width: calc(var(--spinner-rect-width) * 5 + var(--spinner-rect-spacing) * 4);
  height: var(--spinner-height);
  text-align: center;
  position: relative;
  justify-content: space-between;
}

.spinner > div {
  background-color: currentColor;
  height: 100%;
  width: var(--spinner-rect-width);
  display: inline-block;
  animation: squish 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  animation-delay: -1.1s;
}

.spinner .rect3 {
  animation-delay: -1s;
}

.spinner .rect4 {
  animation-delay: -0.9s;
}

.spinner .rect5 {
  animation-delay: -0.8s;
}

@keyframes squish {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
