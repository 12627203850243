/* From: https://raw.githubusercontent.com/jorenvanhee/sass-burger/master/_burger.scss
 * 
 * Burger parts
 * (---) top    -> &::before
 * [---] middle -> &
 * (---) bottom -> &::after
 */

.burger-icon {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  user-select: none;
}

.burger-icon,
.burger-icon::before,
.burger-icon::after {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  outline: 1px solid transparent;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
}

.burger-icon::before,
.burger-icon::after {
  position: absolute;
  content: '';
}

.burger-icon::before {
  top: -8px;
}

.burger-icon::after {
  top: 8px;
}

.burger-icon.is-active {
  background-color: transparent;
}

.burger-icon.is-active::before {
  transform: translateY(8px) rotate(45deg);
}

.burger-icon.is-active::after {
  transform: translateY(-8px) rotate(-45deg);
}
